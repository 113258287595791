import bo from './bo.jpg';
import { useState } from 'react';
import { animated, useSpring } from 'react-spring';

function App() {
  const [spinCount, setSpinCount] = useState(0);

  const spinAngle = spinCount * 360;

  const spinStyle = useSpring({
    transform: `rotate(${-1 * spinAngle}deg)`
  });

  return (
    <div className="mainContainer"> 
      <div className="boControl">
        <button className="boButton" onClick={() => setSpinCount(spinCount + 1)} >
          spin da bo
        </button>
        <div className="whee" style={{opacity: spinCount > 0 ? 1 : 0}}>
          {"whe" + "e".repeat(spinCount)}
        </div>
      </div>
      <animated.img
        className="boPic"  
        style={{
          ...spinStyle,
          maxHeight: "60vh"
        }}
        src={bo}
      />
    </div>
  )
}

export default App;
